import _ from 'lodash';
import { fusionConfigurationPromotionMock, mockSystemFiles } from '../../components/fusion-configuration-promotion/fusion-configuration-promotion-mock';
import { CCApiService } from './api-service';

import { FusionPromotionFile, FusionPromotionResponse, UIConfig } from "./interfaces";

export class FusionConfigurationPromotionService {
  static ApiService: CCApiService;
  static fusionPromotionApi: string;
  static apiBasePath: string;
  static customerId: string;
  static mockResults: FusionPromotionResponse[];
  static instanceFiles: any;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
    this.fusionPromotionApi = `${config.apiBasePath}/customers/${customerId}/configuration-promotions`;
    this.mockResults = fusionConfigurationPromotionMock();
    this.instanceFiles = mockSystemFiles;
  }

  static async getPromotions(): Promise<FusionPromotionResponse[]> {
    return Promise.resolve(this.mockResults);
    // return this.ApiService.performFetch(`${this.fusionPromotionApi}`).then(result => result);
  }

  static async getPromotion(promotionId: string): Promise<FusionPromotionResponse> {
    return Promise.resolve(_.find(this.mockResults, { id: promotionId }));
    // return this.ApiService.performFetch(`${this.fusionPromotionApi}/${promotionId}`).then((result) => result);
  }

  static async getFilesList(systemId: string): Promise<FusionPromotionFile[]> {
    if(systemId === "fbc52d23-5ff5-4f5b-a3e9-57b440247a93") {
      return Promise.resolve(this.instanceFiles[systemId]);
    }
    return Promise.resolve(this.instanceFiles["others"]);
    // GET /api/integrated-system/{integratedSystemId}/repository/branch
  }
}

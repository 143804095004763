import _ from "lodash";
import { fusionInstanceTypes, FusionPromotionResponse, FusionPromotionStates } from "../../services";

const createPromotionMock = (index: number): FusionPromotionResponse => {
  return {
    "id": `d725246b-86f2-4ab3-9f43-48d04bd8c8a${index + 1}`,
    "customerId": "d725246b-86f2-4ab3-9f43-48d04bd8c8a2",
    "type": _.sample([fusionInstanceTypes.MANAGED_FUSION, fusionInstanceTypes.SELF_HOSTED_FUSION]),
    "state": Object.keys(FusionPromotionStates)[index],
    "sourceIntegratedSystem": {
      "id": "d725246b-86f2-4ab3-9f43-48d04bd8c8b1",
      "name": "Source Integrated System"
    },
    "targetIntegratedSystem": {
      "id": "d725246b-86f2-4ab3-9f43-48d04bd8c8b2",
      "name": "Target Integrated System"
    },
    "prUrl": "https://github.com/lucidworks/one-platform-deployment/pull/1486",
    "selectedFiles": [
      {
        "name": "file1.txt",
        "path": "folder/file1.txt",
        "size": 1024,
        "type": "file",
        "download_url": "https://api.github.com/repos/my-org/my-repo/contents/folder/file1.txt"
      },
      {
        "name": "aaa.json",
        "path": "folder/aaa.json",
        "size": 2048,
        "type": "file",
        "download_url": "https://api.github.com/repos/my-org/my-repo/contents/folder/aaa.json"
      }
    ],
    "fileTransformationConfigurations": [
      {
        "index": 1,
        "pathSelectionRegEx": "^.*\/aaa\.json$",
        "target": "aaa.json",
        "replacement": "bbb.json"
      },
      {
        "index": 2,
        "pathSelectionRegEx": "^(.*/)?old_folder(/.*)?$",
        "target": "old_folder",
        "replacement": "new_folder"
      }
    ],
    "contentTransformationConfigurations": [
      {
        "index": 1,
        "pathSelectionRegEx": "^.*\/aaa\.json$",
        "target": "id:dev-pipeline",
        "replacement": "id:prod-pipeline"
      }
    ],
    "createdUser": "user1234",
    "createdDate": "2024-10-15 09:18:11.341098000",
    "approvedBy": "user5654",
    "approvedDate": "2024-10-16 09:18:11.341098000",
  }
};


export const fusionConfigurationPromotionMock = () => _.times(7, (index) => createPromotionMock(index));

export const mockSimpleFiles = [
  {
    "name": "query_pipelines",
    "path": "query_pipelines",
    "download_url": null,
    "type": "dir",
    "restricted": false
  },
  {
    "name": "pipeline1.txt",
    "path": "query_pipelines/pipeline1.txt",
    "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/branch-file1.txt",
    "type": "file",
    "restricted": true
  },
  {
    "name": "branch-file2.json",
    "path": "query_pipelines/branch-file2.json",
    "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/branch-file2.json",
    "type": "file",
    "restricted": false
  }
];

export const mockSourceInstanceFiles =   [
  {
    "name": "query_pipelines",
    "path": "query_pipelines",
    "download_url": null,
    "type": "dir",
    "restricted": false
  },
  {
    "name": "mypipeline-others",
    "path": "query_pipelines/mypipeline-others",
    "download_url": null,
    "type": "dir",
    "restricted": true
  },
  {
     "name": "mypipeline-others.json",
     "path": "query_pipelines/mypipeline-others/mypipeline-others.json",
     "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/mypipeline-others.json",
     "type": "file",
     "restricted": true
  },
  {
     "name": "mypipeline-others2.json",
     "path": "query_pipelines/mypipeline-others/mypipeline-others2.json",
     "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/mypipeline-others2.json",
     "type": "file",
     "restricted": true
  },
  {
    "name": "pipeline1.json",
    "path": "query_pipelines/pipeline1.json",
    "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/pipeline1.json",
    "type": "file",
    "restricted": false
  },
  {
     "name": "my_pipeline_main.json",
     "path": "query_pipelines/my_pipeline_main.json",
     "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/my_pipeline_main.json",
     "type": "file",
     "restricted": false
   },
   {
     "name": "my_pipeline_secondary.json",
     "path": "query_pipelines/my_pipeline_secondary.json",
     "download_url": "https://api.github.com/repos/owner/repo/contents/query_pipelines/my_pipeline_secondary.json",
     "type": "file",
     "restricted": false
   },
   {
     "name": "index_pipelines",
     "path": "index_pipelines",
     "download_url": null,
     "type": "dir",
     "restricted": false
   },
   {
     "name": "pipeline1.json",
     "path": "index_pipelines/pipeline1.json",
     "download_url": "https://api.github.com/repos/owner/repo/contents/index_pipelines/pipeline1-others.json",
     "type": "file",
     "restricted": true
   },
];


export const mockSystemFiles = {
  "fbc52d23-5ff5-4f5b-a3e9-57b440247a93": mockSourceInstanceFiles,
  "others": mockSimpleFiles
}